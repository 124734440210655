<!-- <mat-form-field appearance="outline" class="browser">
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Encuentra lo que buscas...</mat-label>
    <input matInput>
</mat-form-field> -->

<form class="search-container">
    <mat-icon class="search-icon">search</mat-icon>
    <input type="text" id="search-bar" placeholder="Encuentra lo que buscas..."
    i18n-placeholder="@@header.search.placeholder">
    <!-- <a href="#"><img class="search-icon" src="http://www.endlessicons.com/wp-content/uploads/2012/12/search-icon.png"></a> -->
  </form>
