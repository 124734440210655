import { Component, Input } from '@angular/core';
import { PlanInfo} from 'src/app/core/models/plans/plans';


@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss']
})


export class PlanCardComponent {
   @Input() card!: PlanInfo
}