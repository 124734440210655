import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { containsNumbers, preventLetters, setCurrencyFormat } from 'src/app/core/extensions/functions';
import { FilterParams } from 'src/app/core/models/filters/filters.model';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})

export class FilterComponent {
  public filterForm!: FormGroup;
  public panelOpenState: boolean = false;
  public formattedMinAmount: any;
  public formattedMaxAmount: any;
  public maxDate = new Date();

  public minAmount!: string
  public maxAmount!: string

  numericInput!: FormControl;
  preventLetters = preventLetters
  containsNumbers = containsNumbers

  @Output() filtersEvent = new EventEmitter<FilterParams>();

  @Input() isCards?: boolean
  @Input() isReceipts?: boolean


  constructor(private formBuilder: FormBuilder, private datePipe: DatePipe) {
    this.filterForm = this.formBuilder.group({
      startDate: [],
      endDate: [],
      minAmount: [],
      maxAmount: []
    })
  }

  ngOnInit() {
    this.numericInput = this.filterForm.get('minAmount') as FormControl;
  }

  onSubmit() {

    const dataFromForm = {
      startDate: this.datePipe.transform(this.filterForm.get('startDate')?.value, 'YYYY-MM-dd'),
      endDate: this.datePipe.transform(this.filterForm.get('endDate')?.value, 'YYYY-MM-dd'),
      minAmount: this.minAmount,
      maxAmount: this.maxAmount
    }

    const filtersToSend: FilterParams = {}
    dataFromForm.startDate ? filtersToSend.from = dataFromForm.startDate : null;
    dataFromForm.endDate ? filtersToSend.to = dataFromForm.endDate : null;
    dataFromForm.minAmount ? filtersToSend.amountFrom = dataFromForm.minAmount : null;
    dataFromForm.maxAmount ? filtersToSend.amountTo = dataFromForm.maxAmount : null;
    this.filtersEvent.emit(filtersToSend)
   
  }



  formatMinAmount() {
      const cleanDots = this.filterForm.get('minAmount')?.value.replace(/[.]/g, '')
      const replaceComma = cleanDots.replace(/[,]/g, '.')
      replaceComma.replace(/\.$/, '')
      this.minAmount = (Math.round(replaceComma* 100) / 100).toString()
      this.formattedMinAmount = setCurrencyFormat(this.minAmount);  
  }
  

  formatMaxAmount() {
      const cleanDots = this.filterForm.get('maxAmount')?.value.replace(/[.]/g, '')
      const replaceComma = cleanDots.replace(/[,]/g, '.')
      replaceComma.replace(/\.$/, '')
      this.maxAmount = (Math.round(replaceComma* 100) / 100).toString()
      this.formattedMaxAmount = setCurrencyFormat(this.maxAmount);

  }

  resetAll() {
    this.filterForm.reset()
    this.onSubmit()
  }

  isEmptyForm(): boolean {
    return Object.values(this.filterForm.controls).every(control => {
      return control.pristine || control.value === '';
    });
  }



}
