import { Component, OnInit } from '@angular/core';
import { MaterialModule } from 'src/app/shared/material.module';
import { CookiesPolicyComponent } from '../cookies-policy/cookies-policy.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss'],
})
export class LegalNoticeComponent implements OnInit {
  
  constructor() {}

  ngOnInit(): void {}

}
