<div class="modal">
<div class="dialog-header">
    <button class="buttonCloseDialog" mat-button (click)="closeDialog()"> <mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content *ngIf="textContact">
    <h2>Tratamiento de datos personales</h2>
    <p><span class="title-bold">DENARIUS FINANCE, S.A.</span>, como responsable del tratamiento, tratará sus datos con el fin de responder a su solicitud siendo la base legítima su consentimiento.<br><br>Sus datos serán facilitados a encargados de tratamiento para cumplir con la finalidad descrita y/o en los casos en que existan obligaciones legales. Podrá ejercer los derechos que le asisten dirigiéndose a: <span class="anchord">clientes&commat;denariusfinance.com.</span><br><br> Contacto DPD: <span class="anchord">dpo&commat;ingadeconnect.es.</span><br><br> Puede tener acceso a información adicional sobre cómo tratamos sus datos en la sección “Política de Protección de Datos”, en esta misma página web.</p>
    <button class="gold-color-button" (click)="closeDialog()">Cerrar</button>
</mat-dialog-content>
<mat-dialog-content *ngIf="!textContact">
    <h2>Tratamiento de datos personales</h2>
    <p><span class="title-bold">DENARIUS FINANCE, S.A.</span>, como responsable del tratamiento, tratará sus datos con el fin de mantenerle informado de las distintas vacantes a un puesto de trabajo que se produzcan en nuestra organización siendo la base legítima su consentimiento.<br><br> Sus datos serán facilitados a encargados de tratamiento para cumplir con la finalidad descrita y/o en los casos en que existan obligaciones legales. Podrá ejercer los derechos que le asisten dirigiéndose a: <span class="anchord">clientes&commat;denariusfinance.com.</span><br><br> Contacto DPD: <span class="anchord">dpo&commat;ingadeconnect.es.</span> <br><br>Puede tener acceso a información adicional sobre cómo tratamos sus datos en la sección “Política de Protección de Datos”, en esta misma página web.</p>
    <button class="gold-color-button" (click)="closeDialog()">Cerrar</button>
</mat-dialog-content> 
</div>