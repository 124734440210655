import { Segment } from './../../../core/models/plans/plans';
import { Subscription } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/core/services';
import { Router } from '@angular/router';
import { allRegex } from 'src/app/core/extensions/regex';
import { catchError, of, tap } from 'rxjs';

export interface LoginData {
  returnUrl: string
}

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})

export class LoginFormComponent implements OnInit {
  public numberArray!: number[] ;
  public newArray!: number[];
  public password = "";
  public loginForm!: FormGroup;
  public formStep: string = "firstStep";
  public passError: boolean = false
  private formIdentifier: string = 'personal';
active: any;
Segment = Segment

  constructor(private formBuilder: FormBuilder, private auth : AuthService, private router: Router,public dialogRef : MatDialogRef<LoginFormComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: LoginData){


  }
  ngOnInit(){
    this.numberArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
    this.newArray=this.shuffle(this.numberArray)
    this.loginForm = this.formBuilder.group({
      dni:['',[Validators.required, Validators.pattern(allRegex.regexDniAndNie)]],
      password:['', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]],

    })


  }

  addValue(){
    this.loginForm.get('password')?.setValue(this.password)
  }

  shuffle(array:any) {
    let currentIndex = array.length,  randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }
  numClick(val:number) {

      this.password = `${this.password}${val}`;


  }
  nextStep(){
    this.formStep = "secondStep"

  }
  backStep(){
    this.formStep == 'secondStep' ?  this.formStep = "firstStep" :  this.formStep = "secondStep"
  }

  forgotPassword(){
    this.formStep = 'resetPassword'
  }

  deleteDisplay() {
    this.password = this.password.slice(0, -1)
    this.addValue()
  }

  submit(){
    const dni = this.loginForm.get('dni')?.value;
    const password = this.loginForm.get('password')?.value;

    this.auth.signIn(dni, password, Segment.INDIVIDUAL).pipe(
      tap( (res) => {
      const token = res.access_token;
      const [header, payload, signature] = token.split('.');
      const decodedPayload = JSON.parse(atob(payload));
      sessionStorage.setItem('userId', decodedPayload.sub)
      sessionStorage.setItem('state', res.state)
      this.router.navigate([this.data.returnUrl])
      this.dialogRef.close()
    }),
    catchError( (error) => {
      if (error.code === 401) {
        this.passError = true;


      } else {
        console.error('Error en la autenticación:', error);

      }
      return of (401)
    }
    )
    ).subscribe()
  }
}

