
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginAbstractService } from './login.abstract-service';

import { environment } from 'src/environments/environment';
import { LoginRequest, LoginResponse } from '../../models/login';

@Injectable({
  providedIn: 'root'
})
export class LoginService implements LoginAbstractService {

 
   constructor(private http:HttpClient){

   }
 
   login(req:LoginRequest): Observable<LoginResponse> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'});
      
        return this.http.post<LoginResponse>(`${environment.userUrl}/login`, req,  {
          headers: headers,
        })
   }

   loginBusiness(req:LoginRequest): Observable<LoginResponse> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'});
      
        return this.http.post<LoginResponse>(`${environment.companyUrl}/login`, req,  {
          headers: headers,
        })
   }

   loginShareholders(req: LoginRequest): Observable<LoginResponse> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'});
      
        return this.http.post<LoginResponse>(`${environment.shareHolderUrl}/login`, req,  {
          headers: headers,
        })
   }


    
}
