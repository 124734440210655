import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-manager-modal',
  templateUrl: './manager-modal.component.html',
  styleUrl: './manager-modal.component.scss'
})
export class ManagerModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) {

  }

  closeModal() {
    this.dialog.closeAll();
  }


}
