<div class="modal-content">
    <div class="modal-header">
    </div>
    <div class="modal-body">
      <p class="title-modal">¡Un agente de Denarius Finance se pondrá en contacto contigo {{fecha}}!</p>
      <p>Para agilizar el proceso podéis ir preparando la siguiente documentación:</p>
      <div class="content-modal">
        <div class="list">
          <mat-icon>chevron_right</mat-icon>
          <p>Cuentas anuales o cierre contable de los dos últimos años</p>
        </div>
        <div class="list">
          <mat-icon>chevron_right</mat-icon>
          <p>Movimientos contables del año en curso (a la última fecha disponible)</p>
        </div>
        <div class="list">
          <mat-icon>chevron_right</mat-icon>
          <p>Informes de IVA presentados durante el año en curso</p>
        </div>
        <div class="list">
          <mat-icon>chevron_right</mat-icon>
          <p>Pool bancario actualizado (riesgo vivo total en entidades bancarias)</p>
        </div>
        <div class="list">
          <mat-icon>chevron_right</mat-icon>
          <p>Presupuesto de los productos sobre los que se desea realizar el renting</p>
        </div>
    </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="gold-color-button" (click)="closeModal()">{{modalOption}}</button>
    </div>
  </div>


