
import { LoginService } from './login/login.service';
import { Injectable, OnDestroy } from "@angular/core";
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, interval, Observable, of, Subscription, tap } from "rxjs";
import { LoadingService } from "./loading/loading.service";
import { Segment } from '../models/plans/plans';

@Injectable()
export class AuthService implements OnDestroy {
  token$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private logout: Subscription = new Subscription;
  public userRole!: Segment;


  constructor(public jwtHelper: JwtHelperService, private loginService: LoginService, private loadingService: LoadingService ) {
 
  }

  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token!);
  }

  public signIn(username: string, password: string, segment?: Segment): Observable<any> {
    const req = {
      username: username, 
      password: password,
    
    };
    if(segment === Segment.INDIVIDUAL){
      return this.loginService.login(req)
        .pipe(
          tap(res => {
            sessionStorage.setItem('role', Segment.INDIVIDUAL)
            sessionStorage.setItem('userName', `${res.name}`)
            sessionStorage.setItem('token', res.access_token!)
            this.setTokenSessionStorage();
            /* this.setUserRole(Segment.INDIVIDUAL); */
            
          }) 
          
        )

    }else if(segment === Segment.BUSINESS){
      return this.loginService.loginBusiness(req)
        .pipe(
          tap(res => {
            sessionStorage.setItem('role', Segment.BUSINESS)
            sessionStorage.setItem('userName', `${res.name}`)
            sessionStorage.setItem('token', res.access_token!)
            this.setTokenSessionStorage();
            /* this.setUserRole(Segment.BUSINESS); */
          }) 
          
        )
    }else{
      return of (null)
    } 
    

  }
  /* setUserRole(role: Segment): void {
    this.userRole = role;
  }

  // Método para obtener el rol del usuario
  getUserRole(): string {
    return this.userRole
  } */
  
  getTokenSessionStorage(): Observable<boolean> { 
    return this.token$.asObservable();
  }

  setTokenSessionStorage() {
    this.token$.next(this.checkToken());
  }
  checkToken() { 
    if(sessionStorage.getItem('token')) {
      return true
    } else {
      return false
    }
  }

  public signOut() {
    sessionStorage.clear();
    this.setTokenSessionStorage();
  }

  public token() {
    return sessionStorage.getItem('token')
  }

  ngOnDestroy() { 
    this.logout.unsubscribe();
  }
}