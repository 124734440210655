
<div class="atajos">
    <div class="title-actions">
      <h3 class="title" i18n="@@private.area.quick.actions">Acciones rápidas</h3>
      <!-- <mat-icon class="pointer">settings_suggest</mat-icon> -->
    </div>

<div class="actions">
  <ngx-skeleton-loader *ngIf="loader" class="loader" count="1" appearance="line"  [theme]="{
    'background-color': '#f5f5f5',
    'animation-duration': '2s',
    height: '50px', 
    width: '290px',
    margin: '0px'
  }"></ngx-skeleton-loader>
  <div class="action" *ngIf="!loader" (click)="openModal()">
    <div class="content-action">
    <div class="circles">
      <mat-icon>support_agent</mat-icon>
  </div>
  
  <h3>Gestor</h3>
</div>
<mat-icon>chevron_right</mat-icon>
</div>
<ngx-skeleton-loader *ngIf="loader" class="loader" count="1" appearance="line"  [theme]="{
  'background-color': '#f5f5f5',
  'animation-duration': '2s',
  height: '50px', 
  width: '290px',
  margin: '0px'
}"></ngx-skeleton-loader>
    <div class="action" *ngIf="!loader" routerLink="/{{role}}/consultar-documentacion">
      <div class="content-action">
      <div class="circles">
          <mat-icon>folder_open</mat-icon>
      </div>
      
      <h3 i18n="@@documents">Documentos</h3>
    </div>
    <mat-icon>chevron_right</mat-icon>
    </div>
    <ngx-skeleton-loader *ngIf="loader" class="loader" count="1" appearance="line"  [theme]="{
      'background-color': '#f5f5f5',
      'animation-duration': '2s',
      height: '50px', 
      width: '290px',
      margin: '0px'
    }"></ngx-skeleton-loader>
    <div class="action" *ngIf="!loader" routerLink="/{{role}}/transferencias">
      <div class="content-action">
      <div class="circles" >
        <a>
          <mat-icon>swap_horiz</mat-icon>
        </a>
      </div>
      
      <h3 i18n="@@nav.pivate.transfers">Transferencias</h3> 
    </div>
    <mat-icon>chevron_right</mat-icon>
  </div>
   <!--  <div class="action">
      <div class="circles">
        <img class="icon icon-tabler icon-tabler-credit-card-off" src="../../../../assets/images/icons/creditCard.svg" alt="creditCard_icon">
      </div>
      <h3 i18n="@@button.desactivate">Desactivar</h3>
    </div> -->
    <ngx-skeleton-loader *ngIf="loader" class="loader" count="1" appearance="line"  [theme]="{
      'background-color': '#f5f5f5',
      'animation-duration': '2s',
      height: '50px', 
      width: '290px',
      margin: '0px'
    }"></ngx-skeleton-loader>
    <div class="action" *ngIf="!loader" routerLink="/faqs">
      <div class="content-action">
      <div class="circles" >
        <mat-icon>help</mat-icon>
      </div>
      <h3 i18n="@@help.quick.actions">Ayuda</h3>
    </div>
    <mat-icon>chevron_right</mat-icon>
  </div>
  </div>
</div>
