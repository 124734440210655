import { Component, InjectionToken, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs';
import { HeaderLinksModel, NavigationBarModel } from 'src/app/core/models/navBar/navBar';
import { Segment } from 'src/app/core/models/plans/plans';
import { AuthService } from 'src/app/core/services';
import { CompanyService } from 'src/app/core/services/userArea/company.service';
import { UserRoleService } from 'src/app/core/services/userArea/user-role.service';
import { UserService } from 'src/app/core/services/userArea/user.service';

@Component({
  selector: 'app-header-private',
  templateUrl: './header-private.component.html',
  styleUrls: ['./header-private.component.scss']
})
export class HeaderPrivateComponent implements OnInit{
  public role!: string;
  @Input() imageData!: string;
  @Input() linkListPrivate!: HeaderLinksModel;
  public userName!: string;
  public prueba: any;

  
  constructor(
    private auth: AuthService, 
    private router: Router,
    private route: ActivatedRoute, 
    private userRoleService: UserRoleService
    ){}
  ngOnInit(): void {
    sessionStorage.getItem('role')! == Segment.INDIVIDUAL ? this.role = 'particulares':
    sessionStorage.getItem('role')! == Segment.BUSINESS ? this.role = 'empresas': null;
    this.getAvatar()
    this.route.queryParams.subscribe(
      params => {
        params ? this.getAvatar() : null
      }
    )
  }

  getAvatar(){
    this.userRoleService.getStateForRole()?.getProfile().pipe(
      tap((res) => {
        this.userName = res.name
        if (res.avatar) {
          this.imageData = `data:image/jpeg;base64,${res.avatar}`;
        } else {
          if(this.role == 'particulares') {
            res.gender == 'F' ?  this.imageData = '../../../assets/images/avatars/avatar-individual.png' : this.imageData = '../../../assets/images/avatars/individual-hombre.png'
          }else{
            this.imageData = '../../../assets/images/company.webp';
          }
          
        }
      })
    )
    .subscribe();
  }

  logout(){
    this.auth.signOut()
    this.router.navigate(['/'])
  }
  
}
export const MY_TOKEN = new InjectionToken<string>('my-token')