import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent {
  @Input() modalOption! : string
  @Input() fecha! : string
  

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) {
    this.modalOption = this.data.modalOption;
    this.fecha = this.data.fecha
  }

  closeModal() {
    this.dialog.closeAll();
  }

}
