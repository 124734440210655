
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ComparaisonModel, Segment } from 'src/app/core/models/plans/plans';

@Component({
  selector: 'app-comparison-table',
  templateUrl: './comparison-table.component.html',
  styleUrls: ['./comparison-table.component.scss']
})
export class ComparisonTableComponent {
  public hiddenTable!: boolean;
  public titles2!: string[]
  public isManaged!: boolean
  public disabledCheck!:boolean;
  public filtered?: ComparaisonModel

  constructor(private router: Router) { }

  @Input() plansList!: ComparaisonModel[]
  @Input() titles!: string[]
  @Input() customerType!: Segment

  ngOnInit() {
    //de momento se usa titles2, pero luego cada componente pasará titles por input
    this.titles2 = [/* "Tarjeta virtual", "Tarjeta física",  */ "Transferencias", "Transferencias entre cuentas Denarius", "Coste emisión recibos", "Coste devolución recibos", "Ofertas exclusivas para socios", "Soporte telefónico y buzón", "Gestor de cuentas dedicado", /* "Ingresos y retiradas de efectivo",*/];
    if (this.customerType == Segment.BUSINESS || this.customerType === Segment.FREELANCE){
      this.titles2.push("Gestor")
      this.isManaged = true
    }
    this.filtered = this.plansList[0]
    setTimeout(() => {
      this.togglePlans(this.plansList[0].name)
    }, 500);
  }

  toggleVisivility(plan: ComparaisonModel) {
      this.disabledCheck = false
      plan.hiddenTable = !plan.hiddenTable
      this.sortPlans(this.plansList)
      const shownItems = this.plansList.filter(plan => !plan.hiddenTable)
      if (shownItems.length == 1) {
        this.disabledCheck = true
      }
  }

  sortPlans(newPlans: ComparaisonModel[]) {
    this.plansList = newPlans.sort((a, b) => Number(a.id) - Number(b.id))
    this.plansList = newPlans.sort((a, b) => Number(b.hiddenTable) - Number(a.hiddenTable))
  }

  togglePlans(planName: string) {
    this.filtered = this.plansList.find(select => select.name === planName);
  }
   

  turnToIcon(value: boolean) {
    switch (value) {
      case true:
        return 'check'
        break;
      case false:
        return 'remove'
      default:
        return null
        break;
    }
  }

  goToOnboarding(){
    if(this.customerType == Segment.BUSINESS) {
      this.router.navigate(['/onboarding/empresas'])
    } else if (this.customerType == Segment.INDIVIDUAL){
      this.router.navigate(['/onboarding/particulares'])
    } else {
/*       this.router.navigate(['/onboarding/autonomos']) */
    }
  }

}
