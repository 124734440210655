<!-- header bar  -->
<div [ngClass]="isOnboarding ? 'onboarding-page' : 'toolbar_close'">
  <div class="left-content">
    <div class="menu_btn">
      <button (click)="showMenu()" *ngIf="!isMenuOpen">
        <img [src]=" !isLoggedIn ? '../../../../assets/images/icons/burger.svg' : '../../../../assets/images/icons/burger-blue.svg'" alt="" />
      </button>
      <button mat-icon-button (click)="hideMenu()" *ngIf="isMenuOpen">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <img
      class="logo"
      [src]=" !isLoggedIn ? '../../../../assets/images/logos/denarius_mobile.svg' : '../../../../assets/images/logos/logo.svg' "
      alt=""
      routerLink="/"
    />
  
  </div>
  <div class="right-content" *ngIf="!isLoggedIn">
    <button
      class="gold-color-button"
      routerLink="onboarding/empresas"
      i18n="@@landing.public.title"
    >
      Hazte cliente
    </button>
    <button class="gold-stroked-button" routerLink="/empresas/area-privada"><img src="../../../../assets/images/icons/Login.svg" alt=""><p>Acceso</p></button>
  </div>
</div>
<!-- sidenav open -->
<div
  class="menu_opened"
  *ngIf="isMenuOpen"
  [@fadeInLeftOnEnter]
  [@fadeOutLeftOnLeave]
>

  <div class="sidenav" #sidenav>
    <div class="link_list" *ngIf="!optionMenu">
      <div class="buttons" *ngIf="!isLoggedIn && !showButtons">
          <button
            class="gold-color-button"
            routerLink="onboarding/empresas"
            i18n="@@landing.public.title"
            (click)="hideMenu()"
          >
            Hazte cliente
          </button>
          <button (click)="hideMenu()" class="gold-stroked-button" routerLink="/empresas/area-privada"><img src="../../../../assets/images/icons/Login.svg" alt=""><p>Acceso</p></button>
      </div>
      <div *ngIf="!optionMenu" [@fadeInLeftOnEnter] class="linkListContainer">
        <img src="../../../../assets/images/box_gold_light.webp" alt="">
        <app-nav-bar
          [isBurgerMenu]="true"
          [linkList]="isLoggedIn ? linkListPrivate : linkListPublic"
          (closeMenuEvent)="receiveCloseMenu($event)"
        ></app-nav-bar>
      </div>
      <div
        class="list_item"
        [@fadeInRightOnEnter]
        (click)="hideMenu()"
      >
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="logout_button" *ngIf="isLoggedIn">
      <button
        class="gold-color-button"
        (click)="hideMenu(); logout()"
      >
        Cerrar sesión
      </button>
    </div>
  </div>
</div>
