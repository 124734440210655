import { Component } from '@angular/core';

@Component({
  selector: 'app-my-products-card',
  templateUrl: './my-products-card.component.html',
  styleUrl: './my-products-card.component.scss'
})
export class MyProductsCardComponent {

}
