import { environment } from './../../../../environments/environment';
import { CookiesPolicyComponent } from '../../components/cookies-policy/cookies-policy.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PrivacyPolicyComponent } from 'src/app/shared/components/privacy-policy/privacy-policy.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
@ViewChild('empleo', { static: false }) empleoSection!: ElementRef;
private path: string = "../../../assets";
public environment = environment;
public isProductionEnviroment: boolean = environment.production;
@Input() isAuth!: boolean

constructor(
  private matIconRegistry: MatIconRegistry,
  private domSanitizer: DomSanitizer,
  private router: Router
  ){
  this.matIconRegistry.addSvgIcon(
    "facebook",
    this.setPath(`${this.path}/facebook.svg`)
  );
  this.matIconRegistry.addSvgIcon(
    "linkedin",
    this.setPath(`${this.path}/linkedin.svg`)
  );
  this.matIconRegistry.addSvgIcon(
    "twitter",
    this.setPath(`${this.path}/twitter.svg`)
  );
  this.matIconRegistry.addSvgIcon(
    "instagram",
    this.setPath(`${this.path}/instagram.svg`)
  );
}
private setPath(url: string): SafeResourceUrl {
  return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
 }

 scrollToEmpleo() {
  const element = document.getElementById('empleoSection');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}

}
