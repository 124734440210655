import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HideShowAnimation } from '../../animations/hide-show';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  animations:[HideShowAnimation]
})
export class AlertsComponent {
  public icon!: string
 /*  public alertStatus: string = 'ko'
  public alertMessage: string = 'Este es un mensaje de alerta'; */
  public isVisible!: boolean;


  @Input() alertMessage! : string
  @Input() alertStatus! : string
  @Output() alertClosed = new EventEmitter<boolean>();

  ngOnInit() {
    this.isVisible = true
    this.scrollError();
    switch (this.alertStatus) {
      case 'ok':
        this.icon = 'check_circle'
        break;
      case 'ko':
        this.icon = 'error'
        break;
      case 'warning':
        this.icon = 'report_problem'
        break;
      case 'info':
        this.icon = 'info'
        break;
      default: ''
        break;
    }
    return this.icon
  }

  closeAlert() {
    this.isVisible = false;
    this.alertClosed.emit(true);
  }

  scrollError(){
    setTimeout(() => {
      let el = document.getElementById('errorMessage');
      el?.scrollIntoView({behavior:"smooth"});
    }, 100);   
  } 

}
