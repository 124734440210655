import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PlanModel } from 'src/app/core/models/plans/plans';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent {
  @Input() plans!: PlanModel[];
  @Output() sendPlanSelected = new EventEmitter<PlanModel>();
  @Input() onboardingStyle: boolean = false
   constructor(){
    
   }

   selectedPlan(plan: PlanModel){
    this.sendPlanSelected.emit(plan)
   }

}
