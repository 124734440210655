import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ShareholdersData } from '../../models/shareholders/shareholders-data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareHoldersService {

  constructor(private http: HttpClient) {}

   public getMinutesList(params?: HttpParams) {
    return this.http.get<ShareholdersData>(`${environment.shareHolderUrl}/minutes`, { params })
  }
  public uploadMinutes(doc: any){
    return this.http.post<any>(`${environment.shareHolderUrl}/minutes`, doc)
  }
  public downloadMinutes(id: string){
    return this.http.get<Blob>(`${environment.shareHolderUrl}/minutes/${id}`, {responseType: 'blob' as 'json'})
  }
  public deleteMinutes(id: string){
    return this.http.delete<Blob>(`${environment.shareHolderUrl}/minutes/${id}`)
  }
  public changePasswordSh(body: {password:string, rePassword:string}): Observable<any>{
    return this.http.post<any>(`${environment.shareHolderUrl}/change/password`, body)
  }
  
}

