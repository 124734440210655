import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-switch-lang',
  templateUrl: './switch-lang.component.html',
  styleUrls: ['./switch-lang.component.scss']
})

export class SwitchLangComponent {

  /*   public language: any;
    public selectForm!: FormGroup;
    public selectData!: any;
  
    constructor( private formBuilder: FormBuilder ){
      this.selectData = [
      {
        value: 'es',
        viewValue: 'Español',
      },
      {
        value: 'en',
        viewValue: 'English'
      },
    ]
      this.selectForm = this.formBuilder.group({
        locale: ['',[]]
      })
      
    }
    ngOnInit(): void {
      if(sessionStorage.getItem('locale') == 'es'){
        this.language = 'Español';
      }else if(sessionStorage.getItem('locale') == 'en'){
        this.language = 'English';
      }else{
        this.language = 'Español';
      }
      this.selectForm.valueChanges.subscribe( res => {
        this.changeLocale(this.selectForm.get('locale')?.value)
        console.log(res)
      })
    }
  
    changeLocale(locale: string) {
      sessionStorage.setItem('locale', locale);
      location.reload()
    } */
  public selectedLanguage!: any;
  public selectedLanguageValue!: string;
  public selectedLanguageImg!: string;
  public languages = [
    {
      key: 'es',
      value: "es",
      img: "../../../../assets/images/spain.png"
    },
    {
      key: 'en',
      value: "en",
      img: "../../../../assets/images/english.png"
    }
  ];

  ngOnInit() {
    this.loadSelectedLanguage();
  }


  loadSelectedLanguage() {
    const storedLocale = sessionStorage.getItem('locale');
    this.selectedLanguage = storedLocale || 'es'; // Valor por defecto 'es' si no hay selección previa
    const selectedLanguage = this.languages.find(lang => lang.key === this.selectedLanguage);
    if (selectedLanguage) {
      this.selectedLanguageValue = selectedLanguage.value;
      this.selectedLanguageImg = selectedLanguage.img;
    }
  }

  changeLocale(locale: string) {
    sessionStorage.setItem('locale', locale);
    this.loadSelectedLanguage();
    location.reload()
  }


}

