import { Segment } from './../../../core/models/plans/plans';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { allRegex } from 'src/app/core/extensions/regex';
import { AuthService } from 'src/app/core/services';
import { LoginFormComponent, LoginData } from '../login-form/login-form.component';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'app-login-business',
  templateUrl: './login-business.component.html',
  styleUrls: ['./login-business.component.scss']
})
export class LoginBusinessComponent {
  public numberArray!: number[] ;
  public newArray!: number[];
  public password = "";
  public loginForm!: FormGroup;
  public formStep: string = "firstStep";
  public passError: boolean = false
  Segment = Segment
  constructor(private formBuilder: FormBuilder, private auth : AuthService, private router: Router,public dialogRef : MatDialogRef<LoginFormComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: LoginData){
  }
  ngOnInit(){
    this.numberArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
    this.newArray=this.shuffle(this.numberArray)
    this.loginForm = this.formBuilder.group({
      cif:['',[Validators.required, Validators.pattern(allRegex.regexCIF)]],
      password:['', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]],

    })

  }

  addValue(){
    this.loginForm.get('password')?.setValue(this.password)
  }

  shuffle(array:any) {
    let currentIndex = array.length,  randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }
  numClick(val:number) {

      this.password = `${this.password}${val}`;


  }
  nextStep(){
    this.formStep = "secondStep"

  }

  backStep(){
    this.formStep == 'secondStep' ?  this.formStep = "firstStep" :  this.formStep = "secondStep"
  }


  deleteDisplay() {
    this.password = this.password.slice(0, -1)
    this.addValue()
  }

  forgotPassword(){
    this.formStep = 'resetPassword'
  }



  submit(){
    const cif = this.loginForm.get('cif')?.value;
    const password = this.loginForm.get('password')?.value;
    this.auth.signIn(cif, password, Segment.BUSINESS).pipe(
      tap( (res) => {
      const token = res.access_token;
      const [header, payload, signature] = token.split('.');
      const decodedPayload = JSON.parse(atob(payload));
      sessionStorage.setItem('userId', decodedPayload.sub)
      sessionStorage.setItem('state', res.state)
      this.router.navigate([this.data.returnUrl])
      this.dialogRef.close()
    }),
    catchError( (error) => {

      if (error.code === 401) {
        this.passError = true;

      } else {
        console.error('Error en la autenticación:', error);

      }
      return of (401)
    }
    )
    ).subscribe()
  }
}
