import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { CompanyService } from './company.service';
import { Segment } from '../../models/plans/plans';
import { UserState } from '../../models/onboarding/onboarding';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  public role!: string
  constructor(private userService: UserService, private businessService: CompanyService) { }

  getStateForRole() {
    this.role = sessionStorage.getItem('role')!;
    if (this.role === Segment.INDIVIDUAL) {
      return this.userService
    } else if (this.role === Segment.BUSINESS) {
      return this.businessService
    }
    return null
 
  }
}
