import { LoginFormComponent } from '../../../shared/layout/login-form/login-form.component';
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../auth.service";
import { LoginBusinessComponent } from 'src/app/shared/layout/login-business/login-business.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(readonly auth: AuthService, private router: Router, private dialog: MatDialog) {}

  public prompt(returnUrl: string) {
    if (returnUrl.includes('/empresas') && !this.auth.isAuthenticated()) {
      // Si returnUrl contiene '/empresas', abre el diálogo de empresas
      this.dialog.open(LoginBusinessComponent, { data: { returnUrl: returnUrl }, disableClose: true });
    } else if (returnUrl.includes('/particulares') && !this.auth.isAuthenticated()) {
      // Si returnUrl contiene '/particulares', abre el diálogo de particulares
      this.dialog.open(LoginFormComponent, { data: { returnUrl: returnUrl }, disableClose: true });
    } 
  }
 
  public disconnect(jumpTo: string) {
    this.auth.signOut()
    this.router.navigateByUrl(jumpTo);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Obtén el rol del usuario desde tu servicio de autenticación
    const userRole = sessionStorage.getItem('role');
    if (route.data['roles'] && route.data['roles'].indexOf(userRole) === -1) {
      // El usuario no tiene un rol permitido para acceder a esta ruta
      this.prompt(state.url);
      this.disconnect('/para-empresas')
      return false;
    }else{
      return true;
    }
  }
  /* canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
     if (this.auth.isAuthenticated()) {
      return true;
    } else {
      if(this.router.url == '/'){
         this.router.navigate(['/empresas']) 
      }
      this.prompt(state.url);
      return false;
    }
  } 
  
  // auth.guard.ts


} */


}