<div class="chart-container">
  <div class="graph">
    <div class="topSection">
      <div class="topSectionss">
        <div class="yourIncome"><span class="body-default grey-dark">Tu Comparativa</span></div>
        <div class="dollarDisplay styles__title-main_FJlXy">
          <p>Total <span class="dollarDisplay_span">{{ totalBalanceToday | currency:'€' }}</span></p>
        </div>
      </div>
    </div>
    <div class="deltaRow">
      <div class="dropdownContainer">
        <button #dropdownMenu (click)="toggleDropdown()" type="button" class="button small tertiary buttonWithContent" value="30d" data-disclosure="" aria-expanded="false" aria-haspopup="menu" id="id-qu5tkq" aria-controls="id-rrdqwf">
          <div class="body-default" [innerHTML]="timeIntervalDisplayText"></div>
          <mat-icon>arrow_downward</mat-icon>
        </button>
        <div role="presentation" id="dropdownMenu" *ngIf="showDropdown" style="position: absolute; top: 0px; left: 0px; z-index: 4; --popover-overflow-padding: 8px;">
          <div hidden="" data-dialog="" role="menu" tabindex="0" aria-orientation="vertical" class="dropdownmenu buttonMenuContainer buttonList" style="position: relative;" id="rrdqwf" #rrdqwf aria-labelledby="id-qu5tkq">
            <div *ngFor="let timeInterval of timeIntervals" (click)="toggleDropdownOption(timeInterval.value)" data-composite-hover="" data-command="" role="menuitem" class="buttonMenuOption buttonListEntry" id="id-{{timeInterval.id}}" tabindex="-1" [class.selected]="selectedTimeInterval === timeInterval.value">
              <span class="interval">{{ timeInterval.text }} </span>
            </div>
          </div>
        </div>
      </div>
      <div class="deltaAmountContent">
        <mat-icon>arrow_upward</mat-icon>
        <div class="deltaAmount">
          <div class="dollarDisplay body-default text-default">
            <span>{{ totalIncomeToday | currency:'€' }}</span>
          </div>
        </div>
        <div class="deltaAmountContent">
          <mat-icon>arrow_downward</mat-icon>
          <div class="deltaAmount">
            <div class="dollarDisplay body-default text-default">
              <span>{{ totalExpensesToday | currency:'€' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 100%;">
      <canvas #lineChart id="line-chart"></canvas>
    </div>
  </div>
</div>