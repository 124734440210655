<div class="card-container">
  <section class="sections">
    <div class="text-emoji" *ngFor="let item of card.content">
      <p>{{item.emoji}}</p>
      <p>
        {{ item.text }}
      </p>
    </div>
  </section>
</div>
