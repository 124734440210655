import {Component, Inject, Input} from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogRef,
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-modal-condiciones-uso',
  templateUrl: './modal-condiciones-uso.component.html',
  styleUrls: ['./modal-condiciones-uso.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, CommonModule, MatIconModule],
})


export class ModalCondicionesUsoComponent {

public textContact: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private dialog: MatDialog,){
      if(data.contact == 'contact'){
        this.textContact = true
      } else if (this.data.employee == 'employee'){
        this.textContact = false
      }
    }
   
  closeDialog(){
    this.dialog.closeAll()
  }
}
