<div class="validation_container">
    <div class="logo_img">
        <img src="../../../../assets/images/logos/logo.svg" alt="logo_denarius">
        @if (!isDisabledClose) {
        <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
        }
    </div>
    <h2 class="validation_title">Verificación telefónica <span *ngIf="businessStateOnboarding">{{businessStateOnboarding == 'REGISTERED' ? '1' : '2'}} / 2</span></h2>
    <div class="images">
        <img class="image-sms" src="../../../../assets/images/Message.webp" alt="">
        <div class="circle"></div>
    </div>
        @if (businessStateOnboarding) {
            <div class="stepper_container">
                <div [ngClass]="{
                    'active-step': businessStateOnboarding == 'REGISTERED',
                    'disabled-step': businessStateOnboarding == 'REPRESENTATIVE_PHONE_VERIFIED'
                }">
                    <div class="icon">
                        <p class="number">1</p>
                    </div>
                    <div class="step">
                        <h3>Pin SMS del apoderado:</h3>
                        <h3> <strong>{{this.representative}}</strong></h3>
                    </div>
                </div>
                <img class="stepper-img" src="../../../../assets/images/icons/chevronRight.svg" alt="">
                <div [ngClass]="{
                    'active-step': businessStateOnboarding == 'REPRESENTATIVE_PHONE_VERIFIED',
                    'disabled-step': businessStateOnboarding == 'REGISTERED'
                }">
                    <div class="icon">
                        <p class="number">2</p>
                    </div>
                    <div class="step">
                        <h3>Pin SMS de la empresa</h3>
                    </div>
                </div>
            </div>
        }
    <p class="validation-content" i18n="@@sms.code.description">Introduce el código que te hemos enviado por SMS al
        siguiente número: </p>
    <p class="validation-content-phone" *ngIf="phoneNumberValidation">{{phoneNumberValidation}}</p>
    <div class="digit-input-container" *ngIf="!isMobile">
        <input type="text" [(ngModel)]="digits['digit1']" name="digit1" maxlength="1"/>
        <input type="text" [(ngModel)]="digits['digit2']" name="digit2" maxlength="1"/>
        <input type="text" [(ngModel)]="digits['digit3']" name="digit3" maxlength="1"/>
        <input type="text" [(ngModel)]="digits['digit4']" name="digit4" maxlength="1"/>
    </div>
    <div class="mobile-digit-input-container" *ngIf="isMobile">
        <input type="text" maxlength="4" [formControl]="validationForm"/>
      </div>
    <mat-error class="digit_error"
        *ngIf="!validationForm.valid && validationForm.dirty && !validationForm.hasError('resError')">Código
        Incorrecto</mat-error>
    <mat-error class="digit_error"
        *ngIf="validationForm.hasError('resError')">{{validationForm.getError("resError")}}</mat-error>
    <button class="gold-color-button" [disabled]="!validationForm.valid" (click)="onSubmit()">Verificar
        teléfono</button>
    <button class="validation-content-a" i18n="@@sms.verification.code.resend"
        (click)="reqSmsVerify()">No he recibido el SMS</button>
    <button class="disconnect_btn"
        (click)="disconnect()">Salir</button>
    <div class="loader" *ngIf="loader"></div>
</div>