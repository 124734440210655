import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fadeInLeftOnEnterAnimation, fadeOutLeftOnLeaveAnimation,fadeInRightOnEnterAnimation,fadeOutRightOnLeaveAnimation } from 'angular-animations';
import { HeaderLinksModel, NavigationBarModel } from 'src/app/core/models/navBar/navBar';
import { AuthService } from 'src/app/core/services';
import { SideNavService } from 'src/app/core/services/side-nav/side-nav.service';
import { UserRoleService } from 'src/app/core/services/userArea/user-role.service';
import { Segment } from 'src/app/core/models/plans/plans';
import { filter } from 'rxjs';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [fadeInLeftOnEnterAnimation({ duration: 400 }), fadeOutLeftOnLeaveAnimation({ duration: 400 }),
    fadeOutRightOnLeaveAnimation({ duration: 400 }), fadeInRightOnEnterAnimation({ duration: 400 })]
})
export class SideNavComponent {
  @Input() linkListPublic!: HeaderLinksModel;
  @Input() linkListPrivate!: HeaderLinksModel;
  @Input() isLoggedIn!: boolean;
  @Input() isOnboarding!: boolean;
  @Output() openedEvent = new EventEmitter<boolean>()
  @Output() toggleEvent =  new EventEmitter<boolean>()
  public isMenuOpen: boolean = false
  public windowWidth: any;
  public optionMenu!: boolean;
  breakpoint: number = 760;
  isOpen = false;
  public showButtons = false;
  constructor(
    public nav: SideNavService,
    private router: Router,
    private auth: AuthService,
    ){

  }

  ngOnInit(): void {
  }

  showMenu(){
    this.isMenuOpen = true;
    this.openedEvent.emit(true)
  }
  receiveCloseMenu(event:boolean){
    this.hideMenu()
  }

  toggleShowButtons(){
    this.showButtons = !this.showButtons
  }

  hideMenu(){
    this.isMenuOpen = false;
    this.optionMenu = false;
    this.showButtons = false
    this.openedEvent.emit(false)
  }
  showOptionMenu(){
    this.optionMenu = true
  }
  hideOptionMenu(){
    this.optionMenu = false
  }
  logout(){
    this.auth.signOut()
    this.router.navigate(['/'])
  }

}
