import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SideNavService {
  nav: boolean;
  sideNav: boolean;

  constructor() { 
    this.nav = true;
    this.sideNav = true
  }

  hideNav() { this.nav = false; }

  showNav() { this.nav = true; }

  hideSideNav() { this.sideNav = false }

  showSideNav() { this.sideNav = true}

}
