import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AurenContactForm, EmplymentContactForm, FaqContactForm, InsuranceContactForm,LoanContactForm, RentingContactForm, TurnkeyContactForm } from '../../models/contact/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactService {


  constructor(private http: HttpClient) { }

  createAurenContact(body: AurenContactForm): Observable<AurenContactForm> {
    return this.http.post<AurenContactForm>(`${environment.contactUrl}/auren/contact`, body)
  }

  createRentingContact(body: RentingContactForm): Observable<RentingContactForm> {
    return this.http.post<RentingContactForm>(`${environment.contactUrl}/renting/contact`, body)
  }
  createFaqContact(body: FaqContactForm): Observable<FaqContactForm> {
    return this.http.post<FaqContactForm>(`${environment.contactUrl}/contact-us`, body)
  }

  createEmploymentContact(body: EmplymentContactForm): Observable<EmplymentContactForm> {
    return this.http.post<EmplymentContactForm>(`${environment.contactUrl}/employment/create`, body)
  }
  createLoanContact(body: EmplymentContactForm): Observable<LoanContactForm> {
    return this.http.post<LoanContactForm>(`${environment.contactUrl}/loans/create`, body)
  }
  createInsurancetContact(body: EmplymentContactForm): Observable<InsuranceContactForm> {
    return this.http.post<InsuranceContactForm>(`${environment.contactUrl}/insurances/create`, body)
  }
  public createFormOdontologist(body:any): Observable<any> {
    return this.http.post(`${environment.contactUrl}/odontologists/contact`, body)
  }
  public createTurnkeyContact(body: TurnkeyContactForm): Observable<TurnkeyContactForm> {
    let httpHeaders = new HttpHeaders().set('Accept', '*/*')
    return this.http.post<TurnkeyContactForm>(`${environment.contactUrl}/odontologists/contact/turnkey`, body, {headers: httpHeaders})
  }

  //* SUBIDA Y DESCARGA DE ARCHIVOS PARA CUALQUIER FORMULARIO DE CONTACTO

  uploadFile(body: FormData): Observable<any> {
    return this.http.post<any>(`${environment.contactUrl}/documents/upload`, body,  { responseType: 'text' as 'json'})
  }

  downloadFile(documentId: string): Observable<string> {
    return this.http.get<string>(`${environment.contactUrl}/documents/${documentId}/download`)
  }


}
