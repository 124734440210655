import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReceiptsData } from 'src/app/core/models/receipts/receipts';

@Component({
  selector: 'app-sender',
  templateUrl: './sender.component.html',
  styleUrls: ['./sender.component.scss']
})
export class SenderComponent {

public senderSelected!: string;
public senderForm: FormGroup;
public data:any;


@Input() senderData!: ReceiptsData[];
@Output() senderSelect: EventEmitter<string> = new EventEmitter<string>();


ngOnInit(){
      this.senderForm = new FormGroup({
      senders: new FormControl()
    });
}

constructor(private formBuilder: FormBuilder){
  this.senderForm = this.formBuilder.group({
    senders: ["", [Validators.required]]
  }
  )
}

submitSender():void{
  this.data = {
    senders: this.senderForm.get('senders')?.value
  }
}

senderCapture():void{
  const selectSender = this.senderForm.get('senders')?.value;
  this.senderSelect.emit(selectSender)
  this.submitSender()
}



}
