<div [ngClass]="{
  'faqs-background': !isContact,
  'faqs-background-contact': isContact
}">
  <div *ngIf="!isContact" class="diagonal_header">
    <h1 class="header-title" data-aos="fade-up" data-aos-duration="700" i18n="@@faqs">¡Contáctanos!</h1>
  </div>
  <div class="content-faqs-all">
    <div class="content-faqs" *ngFor="let faq of faqs">
      <button class="box-change" (click)="toggleConfig(faq)">
        <mat-icon>{{ faq.hiddenConfig ? 'expand_more' : 'chevron_right' }}</mat-icon>
        <p>{{ faq.question }}</p>
      </button>
      <div class="hidden-faq" *ngIf="faq.hiddenConfig" [@slideInDownOnEnter] [@collapseOnLeave]>
        <p>{{ faq.answer }}</p>
      </div>
    </div>
  </div>
</div>
