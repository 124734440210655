export function getDocumentDescription(doc: string) {
    let document = {
        title: '',
        description: ''
    };

    switch (doc) {
        case 'NIF':
            document.title = 'Número de Identificación Fiscal';
            document.description = 'Es un identificador único para personas físicas y jurídicas en España.';
            break;
        case 'ESC':
            document.title = 'Escritura de Constitución de la Sociedad';
            document.description = 'Documento que establece la creación de una sociedad, incluyendo sus estatutos y normas.';
            break;
        case 'ESA':
            document.title = 'Escritura de Apoderamiento de los Apoderados';
            document.description = 'Documento que otorga poderes a los apoderados para actuar en nombre de la entidad.';
            break;
        case 'TIR':
            document.title = 'Certificado de Titularidad Real';
            document.description = 'Documento que certifica la titularidad real de una sociedad, firmado por el Órgano de Administración. Por favor, utiliza la plantilla que puedes descargar en el link de abajo y adjúntalo firmado digitalmente por quien tenga poderes de representación..';
            break;
        case 'RMA':
            document.title = 'Escritura del Órgano Actual de Administración';
            document.description = 'Documento que detalla las funciones y responsabilidades del órgano actual de administración de una entidad.';
            break;
        case 'PEP':
            document.title = 'Declaración de la Condición de Persona de Responsabilidad Pública';
            document.description = 'Documento utilizado para declarar la condición de Persona de Responsabilidad Pública. Puedes descargar nuestra plantilla y devolverla rellenada y firmada digitalmente o con el sello de la empresa.';
            break;     
        case 'CAN':
            document.title = 'Cuentas Anuales de la Empresa o Impuesto de Sociedades';
            document.description = 'Documento que incluye información financiera sobre el desempeño y situación financiera de la empresa durante un período específico.';
            break;
            case 'IDE':
            document.title = 'Documento de identificación (DNI/NIE)';
            document.description = 'Documento de identificación (DNI/NIE)';
            break;
        default:
            document.title = '';
            document.description = '';
            break;
    }
    
    return document;
}


export enum DOCUMENT_STATE {
    REQUIRED = 'REQUIRED',
    PENDING = 'PENDING',
    PENDING_SEFIDE = 'PENDING-SEFIDE',
    VERIFIED = 'VERIFIED',
    REJECTED = 'REJECTED',
    EXPIRED = 'EXPIRED'
}



export function getDocumentState(state: string) {
    let result = {
        classCss: '',
        viewValue: ''
    };

    switch (state) {
        case DOCUMENT_STATE.REQUIRED:
            result.classCss = 'document_statusRequired';
            result.viewValue = 'Requerido';
            break;
        case DOCUMENT_STATE.PENDING:
            result.classCss = 'document_statusPending';
            result.viewValue = 'Pendiente';
            break;
        case DOCUMENT_STATE.PENDING_SEFIDE:
            result.classCss = 'document_statusSf';
            result.viewValue = 'Pte. Sefide';
            break;
        case DOCUMENT_STATE.VERIFIED:
            result.classCss = 'document_statusOk';
            result.viewValue = 'Verificado';
            break;
        case DOCUMENT_STATE.REJECTED:
            result.classCss = 'document_statusKo';
            result.viewValue = 'Rechazado';
            break;
        case DOCUMENT_STATE.EXPIRED:
            result.classCss = 'document_statusExpired';
            result.viewValue = 'Caducado';
            break;
        default:
            break;
    }
    return result;
}
