import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, forkJoin, map, of } from 'rxjs';
import { Location } from '@angular/common';
import { Documents } from 'src/app/core/models/documents/documents';
import { CompanyService } from 'src/app/core/services/userArea/company.service';
import { DocumentsService } from 'src/app/core/services/documents/documents-service.service';
import { UserService } from 'src/app/core/services/userArea/user.service';
import { DOCUMENT_STATE } from 'src/app/core/extensions/documents.detail';

@Component({
  selector: 'app-user-documents',
  templateUrl: './user-documents.component.html',
  styleUrls: ['./user-documents.component.scss'],
})

export class UserDocumentsComponent {

  public userId!: string;
  _userData: any;
  public docs: Documents[] = [];
  public newStatus: any;
  public url!: string;

  public alertMsg!: string;
  public alertStatus!: string;

  public showAlert: boolean = false;

  @Output() sendError: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  //inputData viene de Host. Son los datos de company
  @Input() set userData(val: any) {
    if (!val) {
      return;
    }
    console.log(val);
    this._userData = val;
    this.userId = this._userData.id;
    console.log(this._userData);
    console.log(this.userId);
    
    this.ngOnInit
  }
  
  ngOnInit(): void {
    this.url = this.location.path();
    if (this.url.includes('empresas')) {
      this.getDocs();
    } else if (this.url.includes('particulares')) {
      this.getDocsIndividual();
    }
  }


  
  constructor(private businessService: CompanyService, private http: HttpClient, private documentsService: DocumentsService, private userService: UserService, private location: Location) {}


  getDocs() {
    this.businessService.getDocuments(this.userId).subscribe((res:any) => {
      this.docs = res.map((doc: any) => {
        if (doc.state == 'PENDING') {
          this.documentsService.getDocument(this.userId, doc.id).subscribe((response:any) => {
            const verificationDenarius = response.verifications.find((ver: any) => ver.method == 'DENARIUS')
            if (verificationDenarius.state == DOCUMENT_STATE.PENDING) {
              doc.state = verificationDenarius.state
            } else {
              doc.state = DOCUMENT_STATE.PENDING_SEFIDE;
            }
          })
        }
        console.log(doc)
        return doc
      })
      console.log(this.docs)
    })
  }

  getDocsIndividual() {
    this.userService.getDocumentState(this.userId).subscribe((res: any) => {
      console.log(res);
      if (res.length === 0) {
        console.log('No documents with a state of PENDING found for user ID', this.userId);
        return;
      }
  
      const promises = res.map((doc: any) => {
        if (doc.state == 'PENDING') {
          return this.documentsService.getDocument(this.userId, doc.id).toPromise().then((response: any) => {
            const verificationDenarius = response.verifications.find((ver: any) => ver.method == 'DENARIUS')
            if (verificationDenarius.state == DOCUMENT_STATE.PENDING) {
              doc.state = verificationDenarius.state
            } else {
              doc.state = DOCUMENT_STATE.PENDING_SEFIDE;
            }
            return doc;
          });
        } else {
          return Promise.resolve(doc);
        }
      });
      Promise.all(promises).then((docs: any[]) => {
        this.docs = docs;
        console.log(this.docs);
      });
    });
  }
  receiveNewStatus(newStatus: any) {
    console.log("Llega al padre", newStatus)
  }


  downloadAllDocs() {
    this.docs.forEach((elem: { file: string; name: string, type: string }) => {
      const urlFile = elem.file
      console.log(urlFile)

      this.http.get(urlFile, { responseType: 'blob' }).subscribe((data:any) => {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const route = url;
        a.download = 'document.jpg';
        a.click();
        window.URL.revokeObjectURL(url);
      });
    })
  }

  alertOk(event: boolean){
    const data = event;
    console.log(data)
    if(event == true) {
      this.showAlert = true
      this.alertMsg = "Documento subido correctamente."
      this.alertStatus = "ok"
      setTimeout(() => {
        this.showAlert = false
      },10000)
    } 
  }

  alertKo(event: boolean){
    const data = event;
    console.log(data)
    if(event == true) {
      this.showAlert = true
      this.alertMsg = "Se ha producido un error. Inténtelo de nuevo."
      this.alertStatus = 'ko'
      setTimeout(() => {
        this.showAlert = false
      },10000)
    }
  }
}
