import { CurrencyPipe } from "@angular/common";

// PARA EL COMPARADOR
export interface ComparaisonModel {
  id?:number;
  name: string,
  price: number,
  freeVirtualCard: boolean,
  freePhysicalCard: number,
  freeTransfers: number,
  /* phoneSupport: boolean,
  mailSupport: number, */
  hiddenTable: boolean,
  freeWithdrawal: number | string,
  manager?: boolean,
  offers: boolean,
  transfersDen: string | number,
  support: string, 
  issueCost: number,
  returnCost: number
}


// PARA LAS TARJETAS
export interface PlanModel {
  id: string
  name: string,
  subtitle?: string,
  price: number,
  description: string
}
export interface PlanInfo {
  title: string,
  active: boolean,
  content: InfoContent[]
}
export interface InfoContent {
  emoji?: string,
  text: string
}

//MODELO DE DATOS PLANES
export interface Plans {
  id: string;
  name: string
  description: string
  segmentId: Segment
  monthlyPrice: number
  cards: Card[]
  accounts: Account[]
  transfers: Transfers[]
  withdrawals: Withdrawal[]
/*   support: Support[] */
  virtualTPV: boolean
  insurances?: boolean
  maxCards?: number
  offers: boolean
  receipt: Receipt
  support: string
}
export interface Card {
  type: CardType
  issueCost: number
  maintenanceCost: number
  free: number
}
export interface Account {
  type?: string
  totalFree: number
  paid: boolean
}
export interface Transfers {
  type: string
  maxFree: number
  cost: number
}
export interface Withdrawal {
  type?: WithdrawalType
  maxFree?: number
  cost: number
}
export interface Support {
  type: SupportChannel
  days: number
}
export interface Receipt {
  issueCost: number
  returnCost: number
}

export class Plan implements Plans { 
  id!:string
  name!: string
  description!: string;
  cards: Card[] = []
  currencyPipe = new CurrencyPipe('es-ES', 'EUR')
  segmentId!: Segment;
  monthlyPrice!: number;
  accounts: Account[] = [];
  transfers: Transfers[] = [];
  withdrawals: Withdrawal[] = [];
/*   support: Support[] = []; */
  virtualTPV: boolean = false;
  insurances?: boolean | undefined;
  maxCards?: number | undefined;
  hiddenTable: boolean = false;
  manager? : boolean;
  offers!: boolean;
  receipt!: Receipt;
  support!: string

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

  toComparisonModel(): ComparaisonModel {
    const virtual = this.cards.filter(card => card.type == CardType.VIRTUAL)
    const physical = this.cards.filter(card => card.type == CardType.PHYSICAL)
    const freeTransfers = this.transfers.map(transf => transf.maxFree).reduce((a, b) => a + b, 0)
    const freePhysicalCards = physical.map(card => card.free).reduce((a, b) => a + b, 0)
    /* const phoneSupport = this.support.some(item => item.type == SupportChannel.PHONE)
    const mailSupport = this.support.find(item => item.type == SupportChannel.MAILBOX)
    const mailSupportDays = mailSupport!.days */
    const freeWithdrawal = this.withdrawals[0].type == WithdrawalType.ATM?`${this.withdrawals[0].maxFree!} gratis` : "gratis en oficinas de correos"
    const manager = this.manager
    const offers = this.offers
    const transfersDen = this.transfers[0].type == "DENARIUS" ? "Gratis" : freeTransfers 
    const support = this.support
    const issueCost = this.receipt.issueCost
    const returnCost = this.receipt.returnCost
    return {
      /* id: Number(this.id), */
      name: this.name,
      price: this.monthlyPrice,
      freeVirtualCard: (virtual.length != 0),
      freePhysicalCard: freePhysicalCards,
      freeTransfers: freeTransfers,
      /* phoneSupport: phoneSupport,
      mailSupport: mailSupportDays, */
      hiddenTable: false,
      freeWithdrawal: freeWithdrawal,
      manager: manager,
      offers: offers,
      transfersDen: transfersDen,
      support: support,
      issueCost: issueCost,
      returnCost: returnCost
    }
  }

  toCardModel(): PlanModel {
    const accounts = this.accounts
    const physical = this.cards.filter(card => card.type == CardType.PHYSICAL)
    const virtual = this.cards.find(card => card.type == CardType.VIRTUAL)
    const freeTransfers = this.transfers.map(transf => transf.maxFree).reduce((a, b) => a + b, 0)
    const freePhysicalCards = physical.map(card => card.free).reduce((a, b) => a + b, 0)
    const freeAccounts = accounts.map(account => account.totalFree).reduce((a, b) => a + b, 0)

    return {
      name: this.name,
      subtitle: this.description,
      price: this.monthlyPrice,
      id: this.id,
      description: this.description
    } 
  }
}

export enum Segment {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
  FREELANCE = 'FREELANCE'
}

export enum SupportChannel {
  MAILBOX = 'MAILBOX',
  PHONE = 'PHONE',
}

export enum CardType {
  PHYSICAL = 'PHYSICAL',
  VIRTUAL = 'VIRTUAL'
}

export enum WithdrawalType {
  ATM = 'ATM',
  POSTAL = 'POSTAL'
}
