<div class="card-admin">
  <div class="modal-header">
    <mat-icon class="close" (click)="closeModal()">close</mat-icon>
  </div>
  <div class="modal-body">
    <div class="img" [ngStyle]="{'background-image': 'linear-gradient(0deg, rgba(0,0,0,0.6783088235294117) 18%, rgba(0,0,0,0) 50%), url(' +  admin.picture + ')'}">
      <a *ngIf="admin.linkedin" href="{{admin.linkedin}}"><img src="../../../../assets/images/logos/linkedin_icon.svg" alt=""></a>
    </div>
    <h1>{{ admin.name }}</h1>
    <p>{{ admin.charge }}</p>
    <div class="content">
      <p [innerHTML]="admin.description"></p>
    </div>
  </div>
</div>
