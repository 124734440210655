import { preventLetters } from 'src/app/core/extensions/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Component,
  Inject,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { allRegex } from 'src/app/core/extensions/regex';
import { AuthService } from 'src/app/core/services';
import { Router } from '@angular/router';
import { tap, catchError, of } from 'rxjs';
import { UserRoleService } from 'src/app/core/services/userArea/user-role.service';
import { Segment } from 'src/app/core/models/plans/plans';
import { UserState } from 'src/app/core/models/onboarding/onboarding';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-phone-validation',
  templateUrl: './phone-validation.component.html',
  styleUrls: ['./phone-validation.component.scss'],
})
export class PhoneValidationComponent {
  public validationForm: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(4),
    Validators.pattern(allRegex.scaPin),
  ]);

 /*  public mobileInputControl: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(4),
    Validators.pattern(allRegex.scaPin),
  ]); */
  
  public phoneNumberValidation!: number;
  public scaData?: any;
  public loading!: boolean;
  public state!: string;
  public loader!: boolean;
  public digitForm!: FormGroup;
  public role!: string;
  public businessStateOnboarding!: UserState;
  public representative!: string;
  public breakpoint: string = '768px';
  public isMobile: boolean = false;
  preventLetters = preventLetters;
  /*   public digit1: string = '';
    public digit2: string = '';
    public digit3: string = '';
    public digit4: string = ''; */
  digits: { [key: string]: string } = {
    digit1: '',
    digit2: '',
    digit3: '',
    digit4: '',
  };
  public isDisabledClose!: boolean;
  @Output() isPhoneValidated = new EventEmitter<boolean>();
  @Input() resMessage!: string;
  @Output() validationEvent = new EventEmitter<FormControl>();
  @Output() clickSubmit = new EventEmitter<boolean>();

/*   @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = window.innerWidth <= this.breakpoint;
  } */

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialogRef: MatDialogRef<PhoneValidationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userRoleService: UserRoleService,
    private auth: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private el: ElementRef
  ) {
    if (data) {
      data.sca ? (this.scaData = data.sca) : null;
      data.businessState
        ? (this.businessStateOnboarding = data.businessState)
        : null;
      data.representative ? (this.representative = data.representative) : null;
    }
  }

  ngOnInit() {
    this.breakpointObserver
    .observe([`(max-width:${this.breakpoint})`])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile  = true
          
        } else {
          this.isMobile  = false
        }
      });
  
    if (this.dialogRef.disableClose) {
      this.isDisabledClose = true;
    } else {
      this.isDisabledClose = false;
    }
    sessionStorage.getItem('role')! == Segment.INDIVIDUAL
      ? (this.role = 'particulares')
      : sessionStorage.getItem('role')! == Segment.BUSINESS
      ? (this.role = 'empresas')
      : null;

    this.userRoleService
      .getStateForRole()
      ?.getProfile()
      .subscribe((res) => {
        if (
          this.businessStateOnboarding &&
          this.businessStateOnboarding === UserState.REGISTERED
        ) {
          this.phoneNumberValidation = res.representative.phone;
        } else {
          this.phoneNumberValidation = res.phone;
        }
      });
  }

  onSubmit() {
    this.loading = true;
    let data: any;
    const formValue = this.validationForm.value;
    if (this.scaData) {
      if (this.scaData.body) {
        data = {
          id: this.scaData.body.id,
          sca: formValue,
        };
      } else {
        data = {
          endpoint: this.scaData.endpoint.replace('[SCA]', formValue),
          method: this.scaData.method,
        };
      }
      this.dialogRef.close(data);
    } else {
      data = formValue;
      this.verifyPhone(data);
    }
    
    this.loading = false;
  }

  reqSmsVerify() {
    const formValue = this.validationForm.value;    
    this.userRoleService
      .getStateForRole()
      ?.requestSmsVerify(sessionStorage.getItem('userId')!)
      .pipe(
        tap((res) => {}),
        catchError((error) => {
          if (error.message === 'phone validated') {
            formValue.setErrors({ resError: 'Teléfono validado.' });
          } else if (
            error.message === 'exceeded max sms. Wait 60 min and try again'
          ) {
            formValue.setErrors({
              resError: 'Excedido límite de sms. Inténtelo de nuevo en 60 min',
            });
          } else {
            formValue.setErrors({ resError: `${error.message}` });
          }
          this.loader = false;
          return of(null);
        })
      )
      .subscribe();
  }

  verifyPhone(data: any) {
    this.loader = true;   
    this.userRoleService
      .getStateForRole()
      ?.verifyOtp(sessionStorage.getItem('userId')!, data)
      .pipe(
        tap((res) => {
          this.state = res.state;
          this.resMessage = 'Se ha verificado correctamente su número';
          setTimeout(() => {
            this.loader = false;
            this.dialogRef.close(data);
            this.router.navigate([`${this.role}/area-privada`]);
          }, 3000);
        }),
        catchError((error) => {
          if (error.message === 'phone validated') {
            this.validationForm.setErrors({ resError: 'Teléfono validado' });
          } else if (error.message === 'attempts incorrect exceeded') {
            this.validationForm.setErrors({
              resError: 'Demasiados intentos incorrectos',
            });
          } else if (error.message === 'otp incorrect') {
            this.validationForm.setErrors({
              resError: 'Código incorrecto',
            })
          }else if (
            error.message === 'exceeded max sms. Wait 60 min and try again'
          ) {
            this.validationForm.setErrors({
              resError: 'Excedido límite de sms. Inténtelo de nuevo en 60 min',
            });
          } else {
            this.validationForm.setErrors({ resError: `${error.message}` });
          }
          this.loader = false;
          return of(null);
        })
      )
      .subscribe();
  }
  
  @HostListener('window:keyup.backspace', ['$event'])
  onInput(target: HTMLInputElement) {
    if(!this.isMobile){
          this.validationForm.reset();
    const inputName = document.activeElement!.getAttribute('name');
    const inputValue = target.value;
    if ((!inputValue || inputValue === '') && inputName !== 'digit1') {
      let previousInput;
      if (inputName === 'digit4') {
        previousInput = this.el.nativeElement.querySelector(
          'input[name="digit3"]'
        ) as HTMLInputElement;
      } else if (inputName === 'digit3') {
        previousInput = this.el.nativeElement.querySelector(
          'input[name="digit2"]'
        ) as HTMLInputElement;
      } else if (inputName === 'digit2') {
        previousInput = this.el.nativeElement.querySelector(
          'input[name="digit1"]'
        ) as HTMLInputElement;
      }
      if (previousInput) {
        if (previousInput.value) {
          previousInput.focus();
        }
        /* this.digits = {
          digit1: '',
          digit2: '',
          digit3: '',
          digit4: '',
        }; */
      }
    }
    }

  }

  //función para avanzar cuando escribes un dígito
  @HostListener('window:keydown', ['$event'])
  onDigitInput(event: KeyboardEvent) {
    if(!this.isMobile){
       if (event.key.match(/^[0-9]$/)) {
      const inputName = document.activeElement!.getAttribute('name');
      if (inputName) {
        let currentInput = inputName;
        let index = Number(currentInput?.charAt(5));
        if (this.digits[currentInput].length === 1 && index < 4) {
          this.validationForm.reset();
          const nextInput = `digit${index + 1}`;
          this.digits[nextInput] = '';
          const nextInputElement = document.getElementsByName(
            nextInput
          )[0] as HTMLInputElement;
          if (nextInputElement) {
            nextInputElement.focus();
          }
        }
        this.validationForm.setValue(Object.values(this.digits).join(''));
      }
    }
    }
   
  }

  @HostListener('window:keyup', ['$event'])
  checkForm(event: KeyboardEvent) {
    if(!this.isMobile){
       if (event.key.match(/^[0-9]$/)) {
      this.validationForm.setValue(Object.values(this.digits).join(''));
    }
    }
   
  }

  disconnect() {
    this.auth.signOut();
    this.dialog.closeAll();
    this.router.navigate(['/para-empresas']);
  }
}
