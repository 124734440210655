<div *ngIf="!onboardingStyle" class="background-plans">
  <div class="card" *ngFor="let item of plans" data-aos="fade-down"  data-aos-duration="800">
    <h2>{{item.name}}</h2>
    <p><span>{{item.price}}€</span> /mes</p>
    <h3>{{item.subtitle}}</h3>
    <div class="cross" (click)="selectedPlan(item)">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
</div>
<!-- contenedor para onboarding sin efectos de scroll -->
<div *ngIf="onboardingStyle" class="onboarding-plans">
  <div class="card" *ngFor="let item of plans" (click)="selectedPlan(item)">
    <h2>{{item.name}}</h2>
    <p><span>{{item.price}}€</span> /mes</p>
    <h3>{{item.subtitle}}</h3>
    <div class="cross">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
</div>

