import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginFormComponent } from 'src/app/shared/layout/login-form/login-form.component';
import { LoginBusinessComponent } from 'src/app/shared/layout/login-business/login-business.component';
import { Segment } from '../../models/plans/plans';

@Injectable({
  providedIn: 'root'
})
export class PublicGuard  {
  constructor(private auth: AuthService, private router: Router, private dialog: MatDialog){

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if (!this.auth.isAuthenticated()) {
        return true;
      } else {
        const role = sessionStorage.getItem('role')
        if(role == Segment.INDIVIDUAL){
          this.router.navigate(['/particulares'])
          return false;
        }else if(role == Segment.BUSINESS){
          this.router.navigate(['/empresas'])
          return false;
        }else{
          return false
        }
      }
  }
  public prompt(returnUrl: string) {
    if (returnUrl.includes('/empresas')) {
      // Si returnUrl contiene '/empresas', abre el diálogo de empresas
      this.dialog.open(LoginBusinessComponent, { data: { returnUrl: returnUrl }, disableClose: true });
    } else if (returnUrl.includes('/particulares')) {
      // Si returnUrl contiene '/particulares', abre el diálogo de particulares
      this.dialog.open(LoginFormComponent, { data: { returnUrl: returnUrl }, disableClose: true });
    } 
  }
  
  public disconnect(jumpTo = '/para-empresas') {
    this.auth.signOut()
    this.router.navigateByUrl(jumpTo);
  }
  
  
}
