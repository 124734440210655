<div class="spinner-container">
<!-- <div class="lds-facebook"><div></div><div></div><div></div></div>
<p i18n="@@loading.spinner">Espere, por favor</p> -->
</div>

<div class="spinner-content">
    <img src="../../../../assets/images/logoneonblack.png">

<lottie-player src="../../../../assets/lottie/spinercirclesGreen.json" background="transparent"  speed="1"  style="width: 150px; height: 150px;"  loop autoplay></lottie-player>
<p i18n="@@spinner" *ngIf="!isDynamic">Cargando...</p>
<p class="dynamic-title" *ngIf="isDynamic">{{dynamicMessage}}</p>
</div>



