<div class="footer-container">
  <div class="principal-content">
      <div class="legal-infos">
        <div class="logo-footer-bot">
          <img class="logo-denarius" src="../../../../assets/images/logos/logo.svg" alt="">
      </div>
          <div class="legal-info">
            <p>Nosotros</p>
            <a class="footer_btn" href="{{environment.environmentUrl}}/quienes-somos" [target]="isAuth ? '_blank' : ''">Quiénes somos</a>
            <a class="footer_btn" href="{{environment.environmentUrl}}/quienes-somos?consejoAdministracion=true" [target]="isAuth ? '_blank' : ''">Consejo de Administración</a>
            <a class="footer_btn" (click)="scrollToEmpleo()" [target]="isAuth ? '_blank' : ''">Trabaja con nosotros</a>
            <a class="footer_btn" href="{{environment.environmentUrl}}/junta-de-accionistas" [target]="isAuth ? '_blank' : ''">Junta de Accionistas</a>
            <a class="footer_btn" i18n="@@contact" href="{{environment.environmentUrl}}/contacto" [target]="isAuth ? '_blank' : ''">Contacto</a>
          </div>
          <div class="legal-info">
              <p>Legal</p>
              <a class="footer_btn" i18n="@@cookies" routerLink="/politica-de-cookies">'Cookies'</a>
              <a class="footer_btn" i18n="@@legal.warning" routerLink="/aviso-legal">Aviso Legal</a>
              <a class="footer_btn" i18n="@@privacy.policy" routerLink="/politica-de-privacidad" >Política de privacidad</a>
          </div>
          <div class="logo-enisa-container">
            <img class="logo-enisa" src="../../../../assets/images/logos/Sello Enisa_Conjunto_Horizontal_Fondos Oscuros-02.svg" alt="">
          </div>
      </div>
  </div>
  <div class="bottom-footer">
    <div class="rights-reserved">
    <p>© 2024 Denarius Finance S.A.  Todos los derechos reservados</p>
    <div class="social">
      <a href="https://www.linkedin.com/company/denarius-bank/"><img src="../../../../assets/images/icons/linkedin.svg" alt=""></a>
      <a href="https://www.youtube.com/channel/UCptEMILwW59oJKxOznxX6Mw"><img src="../../../../assets/images/icons/youtube.svg" alt=""></a>
    </div>
  </div>
  </div>
</div>
