<div class="upload-files_container" [ngClass]="{'contact-forms': isForm}" *ngIf="documentData || fileType">

  @if (documentData && documentData.type === 'IDE' && documentData.partnerId){
  <label for="{{elementId}}">
    <div style="margin-top: 10px;" class="img-preview"
      [ngClass]="{'pickable': uploadState === UploadState.INIT || uploadState === UploadState.FILE_SELECTED}"
      (drop)="onFileSelected($event)" (drag)="onFileSelected($event)">
      <ng-container *ngIf="uploadState === UploadState.FILE_SELECTED && nameFile">
        <img *ngIf="previewIsImage" src="{{preview}}" alt="preview" />
        <mat-icon *ngIf="!previewIsImage">picture_as_pdf</mat-icon>
        <p>{{nameFile}}</p>
      </ng-container>
      <ng-container *ngIf="!preview">
        <p>Seleccionar anverso {{documentData!.type}}</p>
      </ng-container>
    </div>
    <div class="button-container">
      <div class="custom-input-file hidden">
        <input (click)="closeParentAlert()" (change)="onFileSelected($event)" id="{{elementId}}" type="file"
          accept="image/*" class="input-file">
      </div>
    </div>
  </label>

  <label for="{{elementId}}-rev">
    <div style="margin-top: 10px;" class="img-preview"
      [ngClass]="{'pickable': uploadState === UploadState.INIT || uploadState === UploadState.FILE_SELECTED}"
      (drop)="onFileSelected($event, 'rev')" (drag)="onFileSelected($event, 'rev')">
      <ng-container *ngIf="uploadState === UploadState.FILE_SELECTED && nameFileRev">
        <img *ngIf="previewRevIsImage" src="{{previewRev}}" alt="preview" />
        <mat-icon *ngIf="!previewRevIsImage">picture_as_pdf</mat-icon>
        <p>{{nameFileRev}}</p>
      </ng-container>
      <ng-container *ngIf="!previewRev">
        <p>Seleccionar reverso {{documentData!.type}}</p>
      </ng-container>
    </div>
    <div class="button-container">
      <div class="custom-input-file hidden">
        <input (click)="closeParentAlert()" (change)="onFileSelected($event, 'rev')" id="{{elementId}}-rev" type="file"
          accept="image/*" class="input-file">
      </div>
      <div *ngIf=" !hiddenUploadButton" class="custom-input-send">
        <button 
        class="button-upload" 
        type="button" 
        (click)="onSubmit()"
        [style]="uploadState === UploadState.ERROR ?  'color: red' : uploadState === UploadState.SUCCESS ? 'color: green' : null"
        [disabled]="!selectedFile || !selectedFileRev"
        >
          {{buttonSend}} <lottie-player *ngIf="uploadState === UploadState.UPLOADING"
            src="../../../../assets/lottie/loader-dots.json" background="transparent" speed="1"
            style="width: 20px; height: 20px;" loop autoplay></lottie-player>
        </button>
      </div>
    </div>
  </label>
  }
  @else {

  <label for="{{elementId}}">
    <div class="img-preview"
      [ngClass]="{'pickable': uploadState === UploadState.INIT || uploadState === UploadState.FILE_SELECTED}"
      (drop)="onFileSelected($event)" (drag)="onFileSelected($event)">
      <ng-container *ngIf="uploadState === UploadState.FILE_SELECTED && nameFile">
        <img *ngIf="previewIsImage" src="{{preview}}" alt="preview" />
        <mat-icon *ngIf="!previewIsImage">picture_as_pdf</mat-icon>
        <p>{{nameFile}}</p>
      </ng-container>
      <ng-container *ngIf="uploadState === UploadState.INIT && !isForm">
        <p>Seleccionar Archivo</p>
      </ng-container>
    </div>

    <div class="button-container">
      <!-- boton de adjuntar archivos -->
      <div class="custom-input-file">
        <mat-icon>attach_file</mat-icon>
        <input (click)="closeParentAlert()" (change)="onFileSelected($event)" id="{{elementId}}" type="file"
          accept="image/*, application/pdf,application/vnd.ms-excel" class="input-file">
        <p>{{buttonText}}</p>
      </div>

      <!-- boton de subir,subiendo, error... -->
      <div *ngIf="uploadState !== UploadState.INIT && !hiddenUploadButton" class="custom-input-send">
        <button class="button-upload" type="button" (click)="onSubmit()"
          [style]="uploadState === UploadState.ERROR ?  'color: red' : uploadState === UploadState.SUCCESS ? 'color: green' : null">
          {{buttonSend}} 
        </button>
      </div>
    </div>
  </label>
  }

  <!-- BOTÓN DE SUBIR -->

</div>


<form [ngClass]="{
  'form_employment': isForm,
  'personal_doc': isDocumentsComponent,
}">
</form>