import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-admin-team',
  templateUrl: './modal-admin-team.component.html',
  styleUrl: './modal-admin-team.component.scss'
})
export class ModalAdminTeamComponent {
  public admin! : any
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) {
    this.admin = data    
  }

  closeModal() {
    this.dialog.closeAll();
  }
}
