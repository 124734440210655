import { Component, Input } from '@angular/core';
import { fadeInLeftOnEnterAnimation, collapseOnLeaveAnimation, fadeOutLeftOnLeaveAnimation, slideInLeftAnimation, slideInLeftOnEnterAnimation, fadeInRightOnEnterAnimation, slideInDownOnEnterAnimation,  } from 'angular-animations';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  animations: [fadeInLeftOnEnterAnimation({ duration: 400 }),
    fadeOutLeftOnLeaveAnimation(), slideInLeftAnimation(), collapseOnLeaveAnimation(), slideInLeftOnEnterAnimation({ duration: 400 }), fadeInRightOnEnterAnimation({ duration: 400 }), slideInDownOnEnterAnimation()]
})
export class FaqComponent {
  public hiddenConfig:boolean = false
  @Input() isContact: boolean = false;

  faqs = [
/*     {
      question: $localize`:@@faq.question.works:`,
      answer: $localize`:@@faq.answer.works:`,
      hiddenConfig: false
    }, */
    {
      question: $localize`:@@faq.question.cancel:`,
      answer: $localize`:@@faq.answer.cancel:`,
      hiddenConfig: false
    },
    {
      question: $localize`:@@faq.question.negative:`,
      answer: $localize`:@@faq.answer.negative:`,
      hiddenConfig: false
    },
    {
      question: $localize`:@@faq.question.money:`,
      answer: $localize`:@@faq.answer.money:`,
      hiddenConfig: false
    },
    {
      question: $localize`:@@faq.question.iban:`,
      answer: $localize`:@@faq.answer.iban:`,
      hiddenConfig: false
    },
  ]
  toggleConfig(faq: any) {
    this.faqs.forEach((item) => {
      if (item === faq) {
        item.hiddenConfig = !item.hiddenConfig;
      } else {
        item.hiddenConfig = false;
      }
    });
  }
}
