<div [ngClass]="{
    'ok_alert':alertStatus == 'ok',
    'ko_alert':alertStatus == 'ko',
    'warning_alert':alertStatus == 'warning',
    'info_alert':alertStatus == 'info'
    }"
    [@visibleHidden]=" isVisible? 'visible' : 'hidden'"
>
    <div class="alert_left">
        <mat-icon class="alert_icon">{{icon}}</mat-icon>
        <p>{{alertMessage}}</p>
   
    </div>

        <mat-icon class="pointer" (click)="closeAlert()">close</mat-icon>
</div>