import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  public dynamicMessage: string = "Cargando...";
  public isDynamic!: boolean;
  public timerId:any;
  public messages!: string[]
  public count = 0

  constructor(@Inject(MAT_DIALOG_DATA) public data: any){
    if(data){
     this.isDynamic = data.dynamic ? data.dynamic : null;
     this.messages = data.messages;
     this.timerId = setInterval(() => this.changeMessage(),5000)
    }
    
  }


  changeMessage(){
    this.count += 1
    if(this.count > this.messages.length){
      this.count = 1;
    };
    this.dynamicMessage = this.messages[this.count-1]
  }

  ngOnDestroy(){
    clearInterval(this.timerId)
  }

}
