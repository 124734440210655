
<div class="modal-content">
    <div class="modal-header">
      <button mat-icon-button class="close" (click)="closeModal()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="modal-body">
      <div class="column-left">
      <p>Nombre
      </p>
      <p>
        Teléfono
      </p>
      <p>
        Email
      </p>
    </div>
    <div class="column-right">
      <p>Orestis Vilchez</p>
      <p>663571400</p>
      <a href="mailto:ovilchez@denariusfinance.com">ovilchez&#64;denariusfinance.com</a>
    </div>
  </div>
  </div>