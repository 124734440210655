<div class="all-searchs"[ngClass]="{ 'receipts' : isReceipts, 'cards': isCards}">
  <div class="dropdown">
  <mat-accordion  class="expansion-body">
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title> <mat-icon>search</mat-icon>
        <p i18n="@@filter.look.move">Buscar movimiento</p> </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="filterForm">
        <div class="filter-box">
          <div class="period_container">
            <span i18n="@@filter.period">Por periodo:</span>
            <div class="inputs">
              <mat-form-field appearance="outline" color="primary">
                <mat-label i18n="@@filter.from">Desde</mat-label>
                <input formControlName="startDate" matInput [matDatepicker]="pickerStart" [max]="maxDate"/>
                <mat-hint color="primary">dd/MM/AAAA</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="pickerStart"></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="outline" color="primary">
                <mat-label i18n="@@filter.to">Hasta</mat-label>
                <input formControlName="endDate" matInput [matDatepicker]="pickerEnd" [max]="maxDate"/>
                <mat-hint>dd/MM/AAAA</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="amount_container">
            <span i18n="@@filter.amount">Por importe:</span>
            <div class="inputs">
              <mat-form-field floatLabel="always" class="example-full-width" appearance="outline">
                <mat-label i18n="@@min.filter">Mínimo:</mat-label>
                <input formControlName="minAmount" matInput placeholder="0,00€" (keypress)="preventLetters($event)" [value]="formattedMinAmount" (change)="formatMinAmount()"/>
                <mat-icon matSuffix>euro</mat-icon>
              </mat-form-field>
              <mat-form-field floatLabel="always" class="example-full-width" appearance="outline">
                <mat-label i18n="@@max.filter">Máximo:</mat-label>
                <input formControlName="maxAmount" matInput placeholder="0,00€" value="{{ formattedMaxAmount }}"  (change)="formatMaxAmount()"/>
                  <mat-icon matSuffix>euro</mat-icon>
              </mat-form-field>
            </div>
            <div class="button-filter">
              <button mat-stroked-button i18n="@@delete.filters" (click)="resetAll()" [disabled]="isEmptyForm()">
                Borrar filtros
              </button>
              <button mat-flat-button color="primary" i18n="@@button.search" [disabled]="isEmptyForm()" (click)="onSubmit()">
                Buscar
              </button>
            </div>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    </mat-expansion-panel>
  </mat-accordion>
</div>
</div>
