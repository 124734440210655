import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QuickActions } from 'src/app/core/models/card/quick-actions';
import { Segment } from 'src/app/core/models/plans/plans';
import { ManagerModalComponent } from '../manager-modal/manager-modal.component';

@Component({
  selector: 'app-quick-actions',
  templateUrl: './quick-actions.component.html',
  styleUrls: ['./quick-actions.component.scss']
})
export class QuickActionsComponent implements OnInit{
  public role!: string;
  public openModalData: boolean = false;

  constructor(private dialog: MatDialog,){}

  ngOnInit(): void {
    sessionStorage.getItem('role')! == Segment.INDIVIDUAL ? this.role = 'particulares':
    sessionStorage.getItem('role')! == Segment.BUSINESS ? this.role = 'empresas': null;
  }
  @Input() quickActions!: QuickActions[];
  @Input() loader?: boolean;

  openInfo() {
    this.openModalData = true;
  }
  public openModal() {
    this.dialog.open(ManagerModalComponent, {
      disableClose: true,

    });
  }
}
