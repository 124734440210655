import { HeaderLinksModel, NavigationBarModel } from 'src/app/core/models/navBar/navBar';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  fadeInLeftOnEnterAnimation,
  fadeOutLeftOnLeaveAnimation,
  slideInLeftAnimation,
  collapseOnLeaveAnimation,
  slideInLeftOnEnterAnimation,
  fadeInRightOnEnterAnimation,
  slideInDownOnEnterAnimation,
  expandOnEnterAnimation,
} from 'angular-animations';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/core/services';

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  url: string;
}
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  animations: [
    fadeInLeftOnEnterAnimation({ duration: 400 }),
    fadeOutLeftOnLeaveAnimation(),
    slideInLeftAnimation(),
    collapseOnLeaveAnimation(),
    expandOnEnterAnimation({ duration: 500 }),
    slideInLeftOnEnterAnimation({ duration: 400 }),
    fadeInRightOnEnterAnimation({ duration: 400 }),
    slideInDownOnEnterAnimation(),
  ],
})
export class NavBarComponent implements OnInit {
  public _linkList: HeaderLinksModel = { isLoggedIn: false, links:[]}
  public isProductionEnvironment = environment.production;
  @Input() isBurgerMenu: boolean = false;
  @Input() set linkList(val: HeaderLinksModel) {
    if (!val) {
      return;
    } else {
      this._linkList.links = []
      const links = val.links
      this._linkList.isLoggedIn = val.isLoggedIn
      links.forEach((item, index) => {
        item['linkDeployed'] = false;
        this._linkList.links.push(item);
        if (this.isProductionEnvironment && item.hideInProduction) {
          this._linkList.links.splice(index, 1);
        }
      });
    }
  }
  constructor(private authService: AuthService) {}

  @Output() closeMenuEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
  }

  toggleConfig(link: any) {
    this._linkList.links.forEach((item) => {
      if (item === link) {
        item.linkDeployed = !item.linkDeployed;
      } else {
        item.linkDeployed = false;
      }
    });
  }

  closeMenu() {
    this.closeMenuEvent.emit(true);
  }
}
