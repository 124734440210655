<!-- <form [formGroup]="selectForm">
  <mat-form-field appearance="fill" class="form-switch-lang">
    <mat-label>{{language}}</mat-label>
    <mat-select formControlName="locale">
      <mat-option *ngFor="let item of selectData" [value]="item.value">
        {{item.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form> -->
<div class="custom-select">
  <div class="selected-option">
    <mat-icon class="flag-icon">language</mat-icon>
    <span>{{ selectedLanguageValue }}</span>
  </div>
  <div class="options">
    <div *ngFor="let item of languages" (click)="changeLocale(item.key)" class="option">
      <span>{{ item.value }}</span>
    </div>
  </div>
</div>