
export enum UserState {
    REGISTERED = 'REGISTERED',
    PHONE_VERIFIED = 'PHONE_VERIFIED',
    REPRESENTATIVE_DOCUMENTS_VERIFIED = 'REPRESENTATIVE_DOCUMENTS_VERIFIED',
    DOCUMENTS_VERIFIED = 'DOCUMENTS_VERIFIED',
    REPRESENTATIVE_PHONE_VERIFIED = 'REPRESENTATIVE_PHONE_VERIFIED',
    CONTRACT_SIGNED = 'CONTRACT_SIGNED'
  } 

  export interface StateRes{
    state: UserState
  }