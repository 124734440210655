<div class="header_container">
  <div class="upper-container">
    <div class="logo_container">
      <img src="../../../../assets/images/logos/logo.svg" alt="" routerLink="/">
        <!--<app-browser></app-browser>-->
    </div>
    <app-nav-bar [linkList]="linkListPrivate"></app-nav-bar>
        <div class="elements">
          <div class="user_logged">
            <img src={{imageData}} alt="profile_img">
            <p i18n="@@header.greetings">Hola,</p>
            <p class="user">{{userName}}</p>
          </div>
        <div class="dropdown">
            <button class="gold-color-button">
              <p i18n="@@header.private.area.button">Área Privada</p>
              <img  class="icon icon-tabler icon-tabler-arrow-down" src="../../../../assets/images/icons/arrowDown.svg" alt="arrowDown_icon">
              </button>
            <div class="dropdown-content">
            <a routerLink="/{{role}}/area-personal">
              <p i18n="@@header.personal.area">Ver perfil</p>
              <img class="icon icon-tabler icon-tabler-user" src="../../../../assets/images/icons/user.svg" alt="user_icon">
            </a>
            <!-- <a href="#"><p i18n="@@message.private.area">Mensajes</p>
              <img class="icon icon-tabler icon-tabler-send" src="../../../../assets/images/icons/send.svg" alt="send_icon">
            </a>
            <a href="#">
             <p i18n="@@alerts.private.area">Alertas</p>
             <img class="icon icon-tabler icon-tabler-bell-ringing" src="../../../../assets/images/icons/bell.svg" alt="bell_icon">
            </a> -->
            <a (click)="logout()" routerLink="" i18n="@@header.sign.out">Cerrar sesión</a>
            </div>
          </div>
<!--           <div class="language-container">
            <app-switch-lang></app-switch-lang>
          </div> -->
        </div>
      </div>
</div>
<!-- <mat-divider></mat-divider> -->
