<div class="chart-container">
    <div class="graph">
        <div class="topSection">
            <div class="topSectionss">
              <div class="yourIncome"><span class="body-default grey-dark">Tu Balance</span></div>
              <div class="dollarDisplay styles__title-main_FJlXy"><p>Total <span class="dollarDisplay_span">{{ userData.totalBalance | currency:'€' }}</span> </p></div>
            </div>
          </div>
<div class="chart">
<canvas id="myChart"></canvas>
</div>

</div>
</div>