<section class="comparator-container">
  <div class="titles">
   <!--  <h2 class="comparator-title" i18n="@@landing.main.plans">Compara los planes</h2>
    <p class="comparator-subtitle" i18n="@@landing.main.plans.subtitle">Si aún no sabes qué plan elegir, puedes ver en
      detalle cada uno de ellos: </p> -->
      <h2 class="comparator-title" i18n="@@landing.main.plans">Únete a Denarius</h2>
      <p class="comparator-subtitle" i18n="@@landing.main.plans.subtitle"> Descubre en detalle nuestros planes: </p>
  </div>
  <div class="table-container">
    <table class="titles-table">
      <tr>
        <th> </th>
      </tr>
      <tr *ngFor="let title of titles2">
        <td class="titles-container">{{ title }}</td>
      </tr>
    </table>
    <table *ngFor="let plan of plansList" [ngClass]="plan.hiddenTable ? 'hidden-table' : 'normal-table'">
      <tr>
        <th>{{plan.name}} <br>
          <span class="plan-price"> {{plan.price > 0 ? (plan.price | currency: "€ /mes":"symbol":"1.2-2":"es" ):
            'Gratis'}}</span>
          <button class="plan-button gold-color-button" (click)="goToOnboarding()">Contratar</button>
        <!--   <mat-checkbox class="example-margin" [checked]="true" color="primary"
            [disabled]="!plan.hiddenTable && disabledCheck" (change)="toggleVisivility(plan)"></mat-checkbox> -->
        </th>
      </tr>
      <tr>
        <td>{{plan.freeTransfers }} gratis/mes</td>
      </tr>
      <tr>
        <td>{{plan.transfersDen}}</td>
      </tr>
      <tr>
        <td>{{plan.issueCost |  currency: "€":"symbol":"1.2-2":"es"}}</td>
      </tr>
      <tr>
        <td>{{plan.returnCost |  currency: "€":"symbol":"1.2-2":"es"}}</td>
      </tr>
      <tr>
        <td>{{plan.offers ? 'Sí' : 'No'}}</td>
      </tr>
      <tr>
        <td>{{plan.support}}</td>
      </tr>
      <tr>
        <td>{{plan.manager ? 'Sí' : 'No'}}</td>
      </tr>
<!--       <tr>
        <td><mat-icon
            [ngClass]="{'icon-primary': turnToIcon(plan.freeVirtualCard) === 'check', 'icon-gray': turnToIcon(plan.freeVirtualCard) === 'remove'}">{{turnToIcon(plan.freeVirtualCard)}}</mat-icon>
        </td>
      </tr>
      <tr>
        <td *ngIf="plan.freePhysicalCard > 0">{{plan.freePhysicalCard }} gratis</td>
        <td *ngIf="plan.freePhysicalCard == 0"><mat-icon class="icon-gray">remove</mat-icon></td>
      </tr>

      <tr>
        <td *ngIf="plan.freeWithdrawal !== '0 gratis'">{{plan.freeWithdrawal}}</td>
        <td *ngIf="plan.freeWithdrawal == '0 gratis'"><mat-icon class="icon-gray">remove</mat-icon></td>
      </tr>
      <tr *ngIf="isManaged">
        <td><mat-icon
            [ngClass]="{'icon-primary': turnToIcon(plan.manager!) === 'check', 'icon-gray': turnToIcon(plan.manager!) === 'remove'}">{{turnToIcon(plan.manager!)}}</mat-icon>
        </td>
      </tr> -->

    </table>
  </div>
  <div class="second-table">
    <div class="text-container">
      <!--  <mat-button-toggle-group value="{{plansList[0]}}" name="fontStyle" aria-label="Font Style">
          <mat-button-toggle  *ngFor="let plan of plansList" value="{{plan}}" (click)="togglePlans(plan.name)">{{plan.name}}</mat-button-toggle>
        </mat-button-toggle-group> -->
      <div [ngClass]="{'selected': plan.name == filtered?.name , 'list-container': plan.name !== filtered?.name}"
        *ngFor="let plan of plansList">
<!--         <button (click)="togglePlans(plan.name)">{{plan.name}}</button> -->
      </div>
    </div>
    <div *ngIf="filtered" class="header-second-table">
      <p>{{filtered.name}}</p>
      <span class="plan-price"> {{filtered.price > 0 ? (filtered.price | currency: "€ /mes":"symbol":"1.2-2":"es" ):
        'Gratis'}}</span>
      <button class="plan-button" mat-flat-button color="primary">Contratar</button>

    </div>


    <div class="table2">
      <table class="titles-table2">
        <tr *ngFor="let title of titles2">
          <td class="titles-container">{{ title }}</td>
        </tr>
      </table>
      <table *ngIf="filtered">
        <tr>
          <td><mat-icon>{{turnToIcon(filtered.freeVirtualCard)}}</mat-icon></td>
        </tr>
        <tr>
          <td *ngIf="filtered.freePhysicalCard > 0">{{filtered.freePhysicalCard }} gratis</td>
          <td *ngIf="filtered?.freePhysicalCard == 0"><mat-icon class="icon-gray">remove</mat-icon></td>
        </tr>
        <tr>
          <td>{{filtered.freeTransfers }} gratis</td>
        </tr>
        <!-- <tr>
          <td>{{filtered.mailSupport}} días a la semana</td>
  
        </tr> -->
        <tr>
          <td *ngIf="filtered?.freeWithdrawal !== '0 gratis'">{{filtered.freeWithdrawal}}</td>
          <td *ngIf="filtered?.freeWithdrawal == '0 gratis'"><mat-icon class="icon-gray">remove</mat-icon></td>

        </tr>
        <tr *ngIf="isManaged">
          <td><mat-icon>{{turnToIcon(filtered.manager!)}}</mat-icon></td>

        </tr>
      </table>
    </div>
  </div>


</section>