import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountsList, UserData } from '../../../core/models/user/userData';
import { FormGroup, FormBuilder, Form, Validators, FormControl } from '@angular/forms';


@Component({
  selector: 'app-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss']
})

export class SelectAccountComponent {
  public accountForm!: FormGroup;
  @Input() accountData!: AccountsList[];
  @Output() numberAccount: EventEmitter<string> = new EventEmitter<string>();
  
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.accountForm = this.formBuilder.group({
      account: ["", Validators.required] 
    });

    if (this.accountData && this.accountData.length > 0) {
      this.accountForm.get('account')?.setValue(this.accountData[0].id);
    }
  }
  
  ngOnChanges() {
    if (this.accountForm && this.accountData && this.accountData.length > 0) {
      this.accountForm.get('account')?.setValue(this.accountData[0].id);
    }
  }

  submitAccount(): void {
    const selectedAccount = this.accountForm.get('account')?.value;
    this.numberAccount.emit(selectedAccount); 
  }

}
